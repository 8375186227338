<template>
	<div class="container">
		<b>Обща информация</b>
		<p class="lead">
			To read the english version of privacy policy please
			<router-link to="/privacypolicy">click here</router-link>
		</p>
		<p>
			От 25 май 2018 г. влeзe в сила нов регламент за защита на личните данни
			(General Data Protection Regulation), приет от Европейския съюз.
			Регламентът има за цел да гарантира защитата на данните на физическите
			лица от всички държави членки на ЕС и да уеднакви регулациите за тяхната
			обработка.
		</p>
		<!-- <p>
      В качеството си на администратор на лични данни по предоставяне на хостинг
      услуги, petvetmanager.com отговаря на всички изисквания на новата
      регулация, като събира единствено данни на лицата дотолкова, доколкото са
      необходими за предоставянето на услугата, и ги пази отговорно и
      законосъобразно.
    </p> -->
		<!-- <br />
    <b>Информация относно Администратора на лични данни</b>
    <ol>
      <li>Наименование "petvetmanager.com" ООД</li>
      <li>ЕИК/БУЛСТАТ :131449987</li>
      <li>
        Седалище и адрес на управление: гр. София, 1797 , район Изгрев кв. Изток
        бул. „Д-р Г. М. Димитров“ №36
      </li>
      <li>
        Адрес за упражняване на дейността: гр. София, 1797 , район Изгрев кв.
        Изток бул. „Д-р Г. М. Димитров“ №36
      </li>
      <li>
        Данни за кореспонденция: гр. София, 1797 кв. Изток, бул. „Д-р Г. М.
        Димитров“ №36
      </li>
      <li>
        E-mail: ><a href="mailto:volcanic.technologies@gmail.com">dpo@superhosting.bg</a>
      </li>
      <li>Телефон.: 0700 45 800, 02 81 08 999</li>
    </ol>
    <b>Информация относно длъжностното лице по защита на личните данни</b> -->
		<!-- <ol>
      <li>Наименование: Адвокатско дружество „Владимиров Кискинов“</li>
      <li>ЕИК/БУЛСТАТ : 176645870</li>
      <li>
        Седалище и адрес на управление: гр. София, ул. „Найден Геров“ № 6, ет.
        4, офис 7
      </li>
      <li>
        Данни за кореспонденция: гр. София, ул. „Найден Геров“ № 6, ет. 4, офис
        7
      </li>
      <li>Телефон: 02 988 18 28</li>
      <li>
        Email: ><a href="mailto:volcanic.technologies@gmail.com">volcanic.technologies@gmail.com</a>
      </li>
    </ol> -->
		<b>Информация относно компетентния надзорен орган</b>
		<ol>
			<li>Наименование: Комисия за защита на личните данни</li>
			<li>
				Седалище и адрес на управление: гр. София 1592, бул. „Проф. Цветан
				Лазаров” № 2
			</li>
			<li>
				Данни за кореспонденция: гр. София 1592, бул. „Проф. Цветан Лазаров” № 2
			</li>
			<li>Телефон:02 915 3 518</li>
			<li>
				Email: <a href="mailto:kzld@government.bg">kzld@government.bg</a>,
				<a href="mailto:kzld@cpdp.bg">kzld@cpdp.bg</a>
			</li>
			<li>
				Уеб сайт:
				<a href="http://www.cpdp.bg" target="_blank" rel="noopener"
					>www.cpdp.bg</a
				>
			</li>
		</ol>
		„petvetmanager.com“ осъществява дейността си в съответствие със Закона за
		защита на личните данни и Регламент (ЕС) 2016/679 на Европейския парламент и
		на Съвета от 27 април 2016 година относно защитата на физическите лица във
		връзка с обработването на лични данни и относно свободното движение на
		такива данни.
		<br /><br />
		<b
			>Основание за събиране, обработване и съхраняване на Вашите лични данни</b
		>
		<p>
			<strong>Чл. 1.</strong>
			<strong> (1)</strong>
			petvetmanager.com събира и обработва Вашите лични данни във връзка с
			предоставянето на предоставянето на услуга за намиране и търсене на
			квартири и съквартиранти, и по-конкретно въз основа на следното:
		</p>
		<ul>
			<li>Изрично получено съгласие от Вас като клиент;</li>
			<li>
				Спазване на законово задължение, което се прилага спрямо
				petvetmanager.com;
			</li>
			<li>За целите на легитимния интерес на petvetmanager.com.</li>
		</ul>
		<p>
			<strong>(2)</strong>
			petvetmanager.com е администратор на лични данни по отношение на Вашите
			данни като ползватели на нашите услуги. Спрямо личните данни, които Вие
			обработвате, използвайки нашите услуги, petvetmanager.com действа в
			качеството си на обработващ лични данни.
		</p>
		<br />
		<b
			>Цели и принципи при събирането, обработването и съхраняването на Вашите
			лични данни</b
		>
		<p>
			<strong>Чл. 2. (1)</strong>
			petvetmanager.com събира и обработва личните данни, които Вие ни
			предоставяте във връзка с използването на нашите услуги за следните цели:
		</p>
		<ul>
			<li>
				създаване на профил и осигуряване на пълна функционалност при
				предоставянето на услугите ни
			</li>
			<li>
				регистрация на участник в събитие, организирано от petvetmanager.com;
			</li>
			<li>счетоводни цели;</li>
			<li>статистически цели;</li>
			<li>защита на информационната сигурност;</li>
			<li>
				изпращане на информационни съобщения, съобщения за промени в услугата,
				препоръки за подобряване на използването на платформата, нови и
				ъпгрейднати абонаментни планове и др.
			</li>
			<li>
				подобряване и индивидуализиране на услугата чрез предлагане на подходящи
				за Вас оферти, събития и други продукти и услуги, които могат да са от
				интерес за Вас;
			</li>
		</ul>
		<p>
			<strong>(2)</strong>
			petvetmanager.com спазва следните принципи при обработката на Вашите лични
			данни:
		</p>
		<ul>
			<li>законосъобразност, добросъвестност и прозрачност;</li>
			<li>ограничение на целите на обработване;</li>
			<li>
				съотносимост с целите на обработката и свеждане до минимум на събираните
				данни;
			</li>
			<li>точност и актуалност на данните;</li>
			<li>ограничение на съхранението с оглед постигане на целите;</li>
			<li>
				цялостност и поверителност на обработването и гарантиране на подходящо
				ниво на сигурност на личните данни.
			</li>
		</ul>
		<p>
			<strong>(3)</strong>
			При обработването и съхранението на личните данни, petvetmanager.com може
			да обработва и съхранява личните данни с цел защита следните си легитимни
			интереси:
		</p>
		<ul>
			<li>
				изпълнение на задълженията си към Национална агенция по приходите,
				Министерство на вътрешните работи и други държавни и общински органи.
			</li>
		</ul>
		<br />
		<b
			>Какви видове лични данни събира, обработва и съхранява
			petvetmanager.com?</b
		>
		<p>
			<strong>Чл. 3. (1)</strong>
			petvetmanager.com извършва следните операции с личните данни за следните
			цели:
		</p>
		<ul>
			<li>
				Регистрация на потребител в уебсайта в съответствие с изискванията на
				GDPR.
			</li>
			<li>
				Изпращане на информационни съобщения – целта на тази дейност е
				администриране на процеса по изпращане на съобщения до клиентите, които
				се отнасят до подобрения или промени в услугите, надвишени параметри, и
				изтичащи услуги, съгласно договора за предоставяне на услугата.
			</li>
			<li>
				Изпращане на информационен бюлетин (нюзлетър) – целта на тази операция е
				администриране на процеса по изпращане на бюлетини до клиентите, които
				са заявили, че желаят да получават;
			</li>
		</ul>
		<p>
			<strong>(2)</strong>
			petvetmanager.com обработва следните категории лични данни и информация за
			следните цели и на следните основания:
		</p>
		<ul>
			<li>
				Данни: Ваши индивидуализиращи данни (електронна поща, телефон)
				<ul>
					<li>
						<strong>Цел, за която се събират данните:</strong> 1) Регистрация на
						потребител; 2) Осъществяване на връзка с потребителя и изпращане на
						информация към него, включително при изразено желание – за изпращане
						на бюлетини и рекламни съобщения;
					</li>
					<li>
						<strong>Основание за обработка на личните Ви данни </strong>– С
						приемането на общите условия и регистрация в уебсайта, между
						petvetmanager.com и Вас се създава договорно отношение, на което
						основание обработваме Вашите лични данни – чл. 6, ал. 1, б. (б)
						GDPR.
					</li>
				</ul>
			</li>
			<li>
				<strong>Допълнителни данни, предоставяни от Вас</strong> – Ако желаете
				да допълните Вашия профил, можете да попълните в него данни за адрес и
				имейл за технически контакт.
				<ul>
					<li>
						<strong>Цел, за която се събират данните:</strong> Допълване на
						информация за потребителя в потребителския му акаунт.
					</li>
					<li>
						<strong>Основания за обработка на данните:</strong> С приемането на
						общите условия и регистрация в уебсайта petvetmanager.com и Вас се
						създава договорно отношение, на което основание обработваме Вашите
						лични данни – чл. 6, ал. 1, б. (б) GDPR.
					</li>
				</ul>
			</li>
			<li>
				<strong>Други данни, които petvetmanager.com обработва</strong> – При
				влизане в нашия уебсайт или във Вашия профил, petvetmanager.com събира
				данни за използвания IP адрес.
				<ul>
					<li>
						<strong>Цел, за която се събират данните:</strong> Подобряване на
						сигурността на услугата и локализация на интерфейса, статистически и
						маркетингови проучвания.
					</li>
					<li>
						<strong>Основания за обработка на данните:</strong> Обработването е
						необходимо за изпълнение на договор, по който субектът на данните е
						страна - чл. 6, ал. 1, б. (б) на GDPR. До създаването на профил на
						потребителя, IP адресът се събира на основание осъществяване на
						легитимните интереси на администратора – чл. 6, ал. 1, б. (е) от
						GDPR;
					</li>
				</ul>
			</li>
		</ul>
		<p>
			<strong>(3)</strong>
			petvetmanager.com не събира и не обработва лични данни, които се отнасят
			за следното:
		</p>
		<ul>
			<li>разкриват расов или етнически произход;</li>
			<li>
				разкриват политически, религиозни или философски убеждения, или членство
				в синдикални организации;
			</li>
			<li>
				генетични и биометрични данни, данни за здравословното състояние или
				данни за сексуалния живот или сексуалната ориентация.
			</li>
		</ul>
		<p>
			<strong>(4)</strong>
			Личните данни са събрани от petvetmanager.com от лицата, за които се
			отнасят.
		</p>
		<p>
			<strong>(5)</strong>
			petvetmanager.com не извършва автоматизирано взимане на решения с данни.
		</p>
		<br />
		<b>Срок на съхранение на личните Ви данни</b>
		<p>
			<strong>Чл. 4. (1)</strong>
			petvetmanager.com съхранява Вашите лични данни за срок не по-дълъг от
			съществуването на профила ви в уебсайта. След изтичането на този срок,
			petvetmanager.com полага необходимите грижи да изтрие и унищожи всички
			Ваши данни, без ненужно забавяне.
		</p>
		<p>
			<strong>(2)</strong>
			petvetmanager.com Ви уведомява, в случай, че срокът за съхранение на
			данните е необходимо да бъде удължен с оглед изпълнение на целите,
			изпълнение на договора, с оглед легитимни интереси на petvetmanager.com
			или друго.
		</p>
		<p>
			<strong>(3)</strong>
			petvetmanager.com съхранява Вашите данни, дадени на основание съгласие, до
			неговото изрично оттегляне, като това не засяга направени публикации и
			коментари с оглед запазване на смисловата цялост на коментарите.
		</p>
		<p>
			<strong>(4)</strong>
			petvetmanager.com съхранява личните данни, които е необходимо да пази по
			силата на приложимото законодателство за съответния предвиден срок, който
			може да надхвърля срока на съществуване на вашата регистрация.
		</p>
		<br />
		<b>Предаване на вашите лични данни за обработване</b>
		<p>
			<strong>Чл. 5. (1)</strong>
			petvetmanager.com може по собствена преценка да предава част или всички
			Ваши лични данни на обработващи лични данни за изпълнението на целите за
			обработване при спазване на изискванията на Регламент (ЕС) 2016/679.
		</p>
		<p>
			<strong>(2)</strong>
			petvetmanager.com Ви уведомява в случай на намерение да предаде част или
			всички Ваши лични данни на трети държави или международни организации.
		</p>
		<br />
		<b
			>Вашите права при събирането, обработването и съхранението на личните ви
			данни</b
		>
		<br /><br />
		<b>Оттегляне на съгласието за обработване на личните Ви данни</b>
		<br />
		<p>
			<strong>Чл. 6. (1)</strong>
			Ако не желаете всички или част от Вашите лични данни да продължат да бъдат
			обработвани от petvetmanager.com за конкретна или за всички цели на
			обработване, Вие можете по всяко време да оттеглите съгласието си за
			обработка чрез попълване на формата във Вашия профил или чрез искане в
			свободен текст, което да изпратите на пощата ни -
			volcanic.technologies@gmail.com.
		</p>
		<p>
			<strong>(2)</strong>
			petvetmanager.com може да поиска да удостоверите своята самоличност и
			идентичност с лицето, за което се отнасят данните.
		</p>
		<p>
			<strong>(3)</strong>
			С оттегляне на съгласието за обработване на лични данни, които са
			задължителни за създаването и поддържане на регистрацията Ви за използване
			на услугите, Вашият акаунт ще стане неактивен.
		</p>
		<br />
		<b>Право на достъп</b>
		<p>
			<strong>Чл. 7. (1) </strong>
			Вие имате право да изискате и получите от petvetmanager.com потвърждение
			дали се обработват лични данни, свързани с Вас.
		</p>
		<p>
			<strong>(2)</strong>
			Вие имате право да получите достъп до данните, свързани с него, както и до
			информацията, отнасяща се до събирането, обработването и съхранението на
			личните Ви данни.
		</p>
		<p>
			<strong>(3)</strong>
			petvetmanager.com Ви предоставя при поискване, копие от обработваните
			лични данни, свързани с Вас, в електронна или друга подходяща форма.
		</p>
		<p>
			<strong>(4)</strong>
			Предоставянето на достъп до данните е безплатно, но petvetmanager.com си
			запазва правото да наложи административна такса, в случай на повторяемост
			или прекомерност на исканията.
		</p>
		<br />
		<b>Право на коригиране или попълване</b>
		<p>
			<strong>Чл. 8.</strong>
			Вие можете да коригирате или попълните неточните или непълните лични
			данни, свързани с Вас директно през Вашия профил в уебсайта или с
			отправяне на искане до petvetmanager.com.
		</p>
		<br />
		<b>Право на изтриване („да бъдеш забравен“)</b>
		<p>
			<strong>Чл. 9.(1)</strong>
			Вие имате правото да поискате от petvetmanager.com изтриване на свързаните
			с Вас лични данни, а petvetmanager.com има задължението да ги изтрие без
			ненужно забавяне, когато е налице някое от посочените по-долу основания:
		</p>
		<ul>
			<li>
				личните данни повече не са необходими за целите, за които са били
				събрани или обработвани по друг начин;
			</li>
			<li>
				Вие оттеглите своето съгласие, върху което се основава обработването на
				данните и няма друго правно основание за обработването;
			</li>
			<li>
				Вие възразите срещу обработването на свързаните с Вас лични данни,
				включително за целите на директния маркетинг и няма законни основания за
				обработването, които да имат преимущество;
			</li>
			<li>личните данни са били обработвани незаконосъобразно;</li>
			<li>
				личните данни трябва да бъдат изтрити с цел спазването на правно
				задължение по правото на ЕС или правото на държава членка, което се
				прилага спрямо petvetmanager.com;
			</li>
			<li>
				личните данни са били събрани във връзка с предлагането на услуги на
				информационното общество.
			</li>
		</ul>
		<p>
			<strong>(2)</strong>
			petvetmanager.com не е длъжен да изтрие личните данни, ако ги съхранява и
			обработва:
		</p>
		<ul>
			<li>
				за упражняване на правото на свобода на изразяването и правото на
				информация;
			</li>
			<li>
				за спазване на правно задължение, което изисква обработване, предвидено
				в правото на ЕС или правото на държавата членка, което се прилага спрямо
				Администратора или за изпълнението на задача от обществен интерес или
				при упражняването на официални правомощия, които са му предоставени;
			</li>
			<li>
				по причини от обществен интерес в областта на общественото здраве;
			</li>
			<li>
				за целите на архивирането в обществен интерес, за научни или исторически
				изследвания или за статистически цели;
			</li>
			<li>
				за установяването, упражняването или защитата на правни претенции.
			</li>
		</ul>
		<p>
			<strong>(3)</strong>
			За да упражните правото си на „забравяне“, Вие следва да подадете искане
			през опцията във Вашия профил или писмено искане, изпратено до
			petvetmanager.com, както и да удостоверите своята самоличност и
			идентичност с лицето, за което се отнасят данните преди petvetmanager.com,
			като представите на място своята лична карта за целите на идентификация и
			при необходимост въведете своите данни за вход в профила на лицето, за
			което се отнасят данните, пред служител на petvetmanager.com.
		</p>
		<p>
			<strong>(4)</strong>
			petvetmanager.com не изтрива данните, които има законово задължение да
			съхранява, включително за защита по повод отправени срещу него съдебни
			претенции или доказване на свои права.
		</p>
		<br />
		<b>Право на ограничаване</b>
		<p>
			<strong>Чл. 10. </strong>
			Вие имате право да изискате от petvetmanager.com да ограничи обработването
			на свързаните с Вас данни, когато:
		</p>
		<ul>
			<li>
				оспорите точността на личните данни, за срок, който позволява на
				petvetmanager.com да провери точността на личните данни;
			</li>
			<li>
				обработването е неправомерно, но Вие не желаете личните Ви данни да
				бъдат изтрити, а само използването им да бъде ограничено;
			</li>
			<li>
				petvetmanager.com не се нуждае повече от личните данни за целите на
				обработването, но Вие ги изисквате за установяването, упражняването или
				защитата на свои правни претенции;
			</li>
			<li>
				Възразили сте срещу обработването в очакване на проверка дали законните
				основания на petvetmanager.com имат преимущество пред Вашите интереси.
			</li>
		</ul>
		<b>Право на преносимост</b>
		<p>
			<strong>Чл. 11. (1) </strong>
			Вие можете по всяко време да изтеглите данните, които се съхраняват и
			обработват за Вас във връзка с използване на услугите на
			petvetmanager.com, директно през Вашия профил или с искане по имейл.
		</p>
		<p>
			<strong>(2) </strong>
			Вие можете да поискате от petvetmanager.com директно да прехвърли Вашите
			лични данни към посочен от Вас администратор, когато това е технически
			осъществимо.
		</p>
		<br />
		<b>Право на получаване на информация</b>
		<p>
			<strong>Чл. 12. </strong>
			Вие можете да поискате от petvetmanager.com да Ви информира относно всички
			получатели, на които личните данни, за които е поискано коригиране,
			изтриване или ограничаване на обработването, са били разкрити.
			petvetmanager.com може да откаже да предостави тази информация, ако това
			би било невъзможно или изисква несъразмерно големи усилия.
		</p>
		<br />
		<b>Право на възражение</b>
		<p>
			<strong>Чл. 13. </strong>
			Вие можете да възразите по всяко време срещу обработването на лични данни
			от petvetmanager.com, които се отнасят до Вас, включително ако се
			обработват за целите на профилиране или директен маркетинг.
		</p>
		<br />
		<b>Вашите права при нарушение на сигурността на личните ви данни</b>
		<p>
			<strong>Чл. 14. (1) </strong>
			Ако petvetmanager.com установи нарушение на сигурността на личните Ви
			данни, което може да породи висок риск за Вашите права и свободи, ние Ви
			уведомяваме без ненужно забавяне за нарушението, както и за мерките, които
			са предприети или предстои да бъдат предприети.
		</p>
		<p>
			<strong>(2) </strong>
			petvetmanager.com не е длъжен да Ви уведомява, ако:
		</p>
		<ul>
			<li>
				е предприел подходящи технически и организационни мерки за защита по
				отношение на данните, засегнати от нарушението на сигурността;
			</li>
			<li>
				е взел впоследствие мерки, които гарантират, че нарушението няма да
				доведе до висок риск за правата Ви;
			</li>
			<li>уведомяването би изисквало непропорционални усилия.</li>
		</ul>
		<b>Лица, на които се предоставят личните ви данни</b>
		<p>
			<strong>Чл. 15. </strong>
			За извършване на регистрация на домейн в или извън областта .БГ и след
			подадена заявка от Ваша страна, petvetmanager.com предава необходимата
			информация към съответния регистратор на домейни, който обработва Вашите
			данни като администратор за целите на регистрацията на заявения домейн.
		</p>
		<p>
			<strong>Чл. 16. </strong>
			Администраторът не извършва трансфер на вашите данни към трети държави.
		</p>
		<b>Други разпоредби</b>
		<p>
			<strong>Чл. 17. </strong>
			В случай на нарушаване на правата Ви съгласно горепосоченото или
			приложимото законодателство за защита на личните данни, имате право да
			подадете жалба до Комисията за защита на личните данни, както следва:
		</p>
		<ol>
			<li>Наименование: Комисия за защита на личните данни</li>
			<li>
				Седалище и адрес на управление: гр. София 1592, бул. „Проф. Цветан
				Лазаров” № 2
			</li>
			<li>
				Данни за кореспонденция: гр. София 1592, бул. „Проф. Цветан Лазаров” № 2
			</li>
			<li>Телефон:02 915 3 518</li>
			<li>
				Email: <a href="mailto:kzld@government.bg">kzld@government.bg</a>,
				<a href="mailto:kzld@cpdp.bg">kzld@cpdp.bg</a>
			</li>
			<li>
				Уеб сайт:
				<a href="http://www.cpdp.bg" target="_blank" rel="noopener"
					>www.cpdp.bg</a
				>
			</li>
		</ol>
		<p>
			<strong>Чл. 18. </strong>
			Можете да упражните всичките си права относно защита на Вашите лични данни
			чрез формите, приложени към настоящата информация. Разбира се, тези форми
			не са задължителни и можете да отправите исканията си във всякаква форма,
			която съдържа изявление за това и ви идентифицира като притежател на
			данните.
		</p>
		<p>
			<strong>Чл. 19. </strong>
			Ако съгласието се отнася за трансфер, Администраторът описва възможните
			рискове за трансфера на данните към трети държави при липсата на решение
			за адекватна защита и подходящи средства за защита.
		</p>
		<p>
			<strong>Чл. 20(1). </strong>
			Когато възлагате на petvetmanager.com да обработва лични данни на трето
			лице за целите на използването на услугата, petvetmanager.com действа в
			качеството на обработващ личните данни.
		</p>
		<p>
			<strong>(2). </strong>
			В случаите по ал. 1, petvetmanager.com действа единствено по Ваша
			инструкция като ползвател на услугата и само доколкото може да има контрол
			върху личните данни, които Вие обработвате. petvetmanager.com няма контрол
			върху съдържанието и данните, които Вие като ползвател на услугата
			избирате да зареди в услугата (включително ако тези данни включват или не
			лични данни). В този случай petvetmanager.com няма роля в процеса на
			взимане на решение дали ползвателят използва услугата за обработка на
			личните данни, за какви цели и дали същите са защитени. Съответно,
			отговорността на petvetmanager.com в този случай се ограничава до 1)
			съобразяване с инструкциите на ползвателя на услугата съгласно договора и
			общите условия и 2) предоставянето на информация за услугата и
			функционалностите чрез нейния интерфейс.
			<br />
			<br />
			Тази Задължителна информация е последно актуализирана на 21.03.2019 г.
		</p>

		<v-btn color="grey" dark @click="goBack">
			{{ $t('back') }}
		</v-btn>
	</div>
</template>

<script>
export default {
	methods: {
		goBack() {
			this.$router.go(-1);
		},
	},
};
</script>

<style scoped lang="scss">
.lead {
	border: 1px solid #f0ae1d;
	border-radius: 5px;
	padding: 1rem;

	a {
		color: #f0ae1d;
	}
}
</style>
